<template>
    <v-row align="center" justify="center">
        <v-col cols="1">
            <v-progress-circular indeterminate color="blue-grey darken-3" :size="120">
                <v-icon large>mdi-account-hard-hat</v-icon>
            </v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'project-building',
    data: () => ({}),
    computed: {},
    methods: {},
};
</script>

<style></style>
